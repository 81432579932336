import React from 'react'
import AuthPages from '../../components/AuthPages'

const Register = () => {
  return (
    <div>
      <AuthPages page="register"/>
    </div>
  )
}

export default Register