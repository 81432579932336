import React, { useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";

import Button from "./Button";
import Card from "./Card";
import FormField from "./FormField";
import Text from "./Text";
import Icon from "./Icon";

const OnBoardingDetails = ({ items, children, onClick, backHandler, inputValues, setInputValues }) => {
  const {
    title,
    desc,
    fieldName,
    fieldNameOne,
    textArea,
    type,
    buttonText,
    name,
    backIcon,
    hasChildren,
    options,
    image,
  } = items;


  const [errors, setErrors] = useState({
    field: "",
    textArea: "",
  });

  const onChange = (e) => {
    if (name === "county" || name === "insurance") {
      setInputValues((prev) => ({ ...prev, [name]: e }));

    } else {
      setInputValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onBack = () => {
    setErrors({ field: "", textArea: "" });
    backHandler();
  };

  const onClickHandler = () => {
    if (textArea) {
      if (!inputValues[textArea]) {
        setErrors((prev) => ({ ...prev, textArea: "Field required" }));
      } else {
        setErrors((prev) => ({ ...prev, textArea: "" }));
      }
    }
    if (!inputValues[name]) {
      setErrors((prev) => ({ ...prev, field: "Field required" }));
    } else {
      setErrors((prev) => ({ ...prev, field: "" }));
      onClick();
    }

    if (hasChildren) onClick();
  };
  return (
    <Card className="p-[0px] w-full gap-[40px]">
      {backIcon ? (
        <Button
          buttonIcon={true}
          className="text-[50px] text-bimalink-blue"
          iconRight={
            <Icon className={"bg-white"}>
              <BsArrowLeftShort />
            </Icon>
          }
          onClick={onBack}
        />
      ) : null}
      <Card className={`p-[0px] w-full flex ${image ? "items-center" : ""}`}>
        <Text className={`text-[25px] text-bimalink-blue-gray-800 ${image ? "text-center" : ""}`}>
          {title}
        </Text>
        <Text className={`text-bimalink-blue-gray-700 w-[80%] max-sm:w-full ${image ? "text-center" : ""}`}>
          {desc}
        </Text>
        {!hasChildren && (
          <FormField
            fieldName={fieldName}
            inputType="normal"
            fieldType="normal"
            type={type}
            formContainerStyle={"w-full"}
            fieldRequired={true}
            onChange={onChange}
            name={name}
            value={inputValues[name]}
            error={errors.field}
            options={options}
          />
        )}
        {fieldNameOne && (
          <FormField
            type={"textarea"}
            fieldName={fieldNameOne}
            inputType="normal"
            fieldType="normal"
            formContainerStyle={"w-full h-full"}
            formInputStyle="h-[170px] rounded-[15px] "
            fieldRequired={true}
            name={textArea}
            onChange={onChange}
            value={inputValues[textArea]}
            error={errors.textArea}
          />
        )}
        {children}
      </Card>
      <Button
        className={"w-full h-[62px]"}
        buttonText={buttonText}
        onClick={onClickHandler}
      />
    </Card>
  );
};

export default OnBoardingDetails;
