import React, { useContext, useState } from "react";
import { Dialog } from "@headlessui/react";
import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { RiAddFill } from "react-icons/ri";

import Card from "./Card";
import Logo from "./Logo";
import Image from "./Image";
import Icon from "./Icon";
import { ReactComponent as Notification } from "../../assets/images/Notification.svg";
import User from "../../assets/images/testmonial1.png";
import Text from "./Text";
import { AuthContext } from "../../context/authContext";
import LinkItem from "../LinkItem";
import { ReactComponent as Logout } from "../../assets/images/Logout.svg";
import { dashBoardNavItems } from "../../data";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchIcon.svg";
import FormField from "./FormField";
import Button from "./Button";

const MobileDashNav = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="hidden max-lg:flex bg-transparent rounded-none w-full mx-auto max-sm:w-[90%] max-sm:px-[0px]">
      <Card className="flex-row p-[0px] justify-between items-center w-full bg-transparent">
        <FiMenu
          className="text-bimalink-blue text-[30px] cursor-pointer"
          onClick={() => setIsOpen(true)}
        />
        <Logo
          type="dash"
          logoClass={"max-sm:w-[140px] max-sm:px-[30px] h-full"}
          to="/dashboard/home"
        />
        <Card className={"p-[0px] bg-transparent flex-row items-center"}>
          <Notification />
          <Icon className={"bg-transparent p-[0px] w-[50px] h-[50px]"}>
            <Image src={User} />
          </Icon>
        </Card>
      </Card>
      <Card
        className={"p-[0px] bg-transparent gap-[0px] w-full justify-center"}
      >
        <Text className={"text-[30px] gap-2"}>
          Welcome,
          <span className="text-bimalink-blue">
            {currentUser?.fullname?.split(" ")[0]}{" "}
          </span>
          👋
        </Text>
        <Text className={"text-[20px]"}>Its nice to see you again!</Text>
      </Card>
      <Dialog className="flex" open={isOpen} onClose={() => setIsOpen(false)}>
        <Card
          className={
            "fixed w-[96%] lg:hidden mx-auto inset-0 flex items-center flex-col px-4 h-[95%] my-auto bg-white shadow-bimalink-shadow backdrop-blur-3xl py-[5px]"
          }
        >
          <Dialog.Panel className={"w-full flex flex-col h-full gap-[10px]"}>
            <Card
              className={
                "bg-transparent border-b-solid border-b border-b-bimalink-blue-gray-200 rounded-none max-w-full gap-[0px] p-[0px] flex-row justify-between items-center py-[12px]"
              }
            >
              <Logo className={"w-full h-[30px]"} to="/dashboard/home" onClick={() => setIsOpen(false)} />
              <IoMdClose
                className="text-[30px] text-bimalink-blue hover:bimalink-blue-100"
                onClick={() => setIsOpen(false)}
              />
            </Card>
            <Button
              className={"flex gap-[5px] w-[50%] hover:bg-bimalink-blue-50 mt-[5px]"}
              iconLeft={<RiAddFill className="text-[25px]" />}
              buttonText="Get Quote"
            />
            <FormField
              formContainerStyle="flex-row gap-[15px] items-center bg-white rounded-full px-[20px] py-[10px] mr-auto w-[50%] border-solid border-2 w-full border-bimalink-blue-gray-100 mt-[10px]"
              iconOrComponent={<SearchIcon />}
              showButton={false}
              placeHolder="Search"
            />
            <Card
              className={
                "p-[0px] bg-transparent h-full rounded-none pb-[20px] z-[10]"
              }
            >
              <ul className="flex flex-col mb-auto gap-[5px] w-full">
                {dashBoardNavItems.map(({ icon, name, path }, index) => (
                  <LinkItem
                    key={name + index}
                    to={path}
                    className="flex gap-[22px] rounded-l-[30px] rounded-r-[5px] py-[12px] px-[12px] border-r-solid border-r-[6px] border-r-transparent items-center hover:text-bimalink-blue"
                    activeClassName={
                      "flex gap-[22px] rounded-l-[30px] rounded-r-[5px] bg-bimalink-blue-gray-100 py-[12px] px-[12px] border-r-solid border-r-[6px] border-r-bimalink-blue items-center hover:border-bimalink-blue-900"
                    }
                    onClick={() => setIsOpen(false)}
                  >
                    {icon}
                    {name}
                  </LinkItem>
                ))}
              </ul>
              <LinkItem
                onClick={logout}
                type="link"
                to={"/login"}
                className="flex gap-[22px] rounded-l-[30px] rounded-r-[5px] w-full py-[12px] px-[12px] border-r-solid border-r-[6px] border-r-transparent items-center hover:text-bimalink-blue mt-auto"
              >
                <Logout />
                Logout
              </LinkItem>
            </Card>
          </Dialog.Panel>
        </Card>
      </Dialog>
    </Card>
  );
};

export default MobileDashNav;
