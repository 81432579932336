import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import Support from "./assets/images/support.png";
import Speed from "./assets/images/speed.png";
import Reliable from "./assets/images/reliable.png";
import Security from "./assets/images/security.png";
import Find from "./assets/images/Find.png";
import Save from "./assets/images/Save.png";
import Search from "./assets/images/search.png";
import GetQuote from "./assets/images/get_quote.png";
import Quote from "./assets/images/Quote.png";
import TestOne from "./assets/images/testmonial1.png";
import TestTwo from "./assets/images/testmonial2.png";
import TestThree from "./assets/images/testmonial3.png";
import Plus from "./assets/images/add_faq.png";
import InsuranceHeroOne from "./assets/images/insurance_hero1.png";
import InsuranceHeroTwo from "./assets/images/insurance_hero2.png";
import InsuranceHeroThree from "./assets/images/insurance_hero3.png";
import InsuranceHeroFour from "./assets/images/insurance_hero4.png";
import InsuranceHeroFive from "./assets/images/insurance_hero5.png";
import InsuranceItemOne from "./assets/images/insurance_item1.png";
import InsuranceItemTwo from "./assets/images/insurance_item2.png";
import InsuranceItemThree from "./assets/images/insurance_item3.png";
import InsuranceItemFour from "./assets/images/insurance_item4.png";
import HeroIcon from "./assets/images/hero_icon.png";
import ArrowBack from "./assets/images/arrow_left.png";
import { ReactComponent as HomeDecoration } from "./assets/images/HomPageTuts.svg";
import { ReactComponent as CurlyIcon } from "./assets/images/memphics_curly.svg";
import { ReactComponent as Active } from "./assets/images/Active.svg";
import { ReactComponent as Pending } from "./assets/images/Pending.svg";
import { ReactComponent as Products } from "./assets/images/Products.svg";
import { ReactComponent as ProductsD } from "./assets/images/ProductsD.svg";
import { ReactComponent as Profile } from "./assets/images/Profile.svg";
import { ReactComponent as Insurers } from "./assets/images/Insurers.svg";
import { ReactComponent as Dashboard } from "./assets/images/Dashboard.svg";
import { ReactComponent as Covers } from "./assets/images/Covers.svg";
import { ReactComponent as Notification } from "./assets/images/Notification.svg";
import { ReactComponent as SearchIcon } from "./assets/images/SearchIcon.svg";
import { ReactComponent as Settings } from "./assets/images/Settings.svg";
import { ReactComponent as MoreMenu } from "./assets/images/MoreMenu.svg";
import { ReactComponent as Email } from "./assets/images/Email.svg";
import { ReactComponent as Id } from "./assets/images/Id.svg";
import { ReactComponent as Location } from "./assets/images/Location.svg";
import { ReactComponent as Phone } from "./assets/images/Phone.svg";

// DashBoard

export const dashBoardNavItems = [
  {
    icon: <Dashboard />,
    name: "Dashboard",
    path: "/dashboard/home",
  },
  {
    icon: <Covers />,
    name: "Covers",
    path: "/dashboard/covers",
  },
  {
    icon: <Products />,
    name: "Products",
    path: "/dashboard/products",
  },
  {
    icon: <Profile />,
    name: "Profile",
    path: "/dashboard/profile",
  },
  {
    icon: <Settings />,
    name: "Settings",
    path: "/dashboard/settings",
  },
];

export const dashBoardHomeItems = [
  {
    top: [
      {
        icon: <Active className="text-bimalink-blue" />,
        count: 6,
        name: "Active Covers",
        desc: "Its nice to see you again!",
      },
      {
        icon: <Pending />,
        count: 3,
        name: "Pending Transactions ",
        desc: "Its nice to see you again!",
      },
      {
        icon: <ProductsD />,
        count: 30,
        name: "Insurance Products ",
        desc: "Its nice to see you again!",
      },
      {
        icon: <Insurers />,
        count: 10,
        name: "Insurers",
        desc: "Its nice to see you again!",
      },
    ],
    bottom: [
      {
        titles: [
          "#",
          "Date",
          "End Date",
          "Name",
          "Insurer",
          "Cost",
          "Duration",
          "Status",
        ],
        items: [
          {
            date: "18/5/2020",
            endDate: "25/5/2020",
            name: "Car cover",
            insurer: "Jubilee",
            cost: "Kshs.30000",
            duration: "3 months",
            status: "Active",
          },
          {
            date: "18/5/2020",
            endDate: "25/5/2020",
            name: "Car cover",
            insurer: "Jubilee",
            cost: "Kshs.30000",
            duration: "3 months",
            status: "Active",
          },
          {
            date: "18/5/2020",
            endDate: "25/5/2020",
            name: "Car cover",
            insurer: "Jubilee",
            cost: "Kshs.30000",
            duration: "3 months",
            status: "Active",
          },
        ],
      },
    ],
    side: [
      {
        image: InsuranceHeroOne,
        title: "Car Insurance",
        insurance: "Jubilee",
        count: 200,
      },
      {
        image: InsuranceHeroOne,
        title: "Car Insurance",
        insurance: "Jubilee",
        count: 200,
      },
      {
        image: InsuranceHeroOne,
        title: "Car Insurance",
        insurance: "Jubilee",
        count: 200,
      },
    ],
    sideBottom: [
      {
        title: "Phone Insurance",
        insurance: "Britam",
        amount: "30000.00",
      },
      {
        title: "Phone Insurance",
        insurance: "Britam",
        amount: "30000.00",
      },
    ],
  },
];

export const ProfileItems = [
  {
    insuranceSection: "Insurance preference",
    aboutSection: "About me",
    personalSection: "Personal",
    icons:  [
      <Id />,
      <Phone />,
      <Email />,
      <Location />
    ]
  }
]



// Website

export const primaryNavItems = [
  { name: "Insurances", path: "/insurances" },
  { name: "Blog", path: "/blog" },
  { name: "About us", path: "/about" },
  { name: "Contact us", path: "/contact" },
];

export const HeroItems = [
  {
    subtitle: (
      <p>
        Insure, safeguard and{" "}
        <span className="inline-flex text-bimalink-blue">take control</span> of
        your future Now
      </p>
    ),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Cras cursus ut fames vivamus leo praesent sagittis ultrices`,
    primaryButtonText: "Learn More",
    secondaryButtonText: "Get Quote",
    heroIcon: HeroIcon,
    homeDeco: <HomeDecoration />,
    curlyDeco: <CurlyIcon className="max-sm:w-[58px] w-[75px]" />,
    image1: InsuranceHeroOne,
    image2: InsuranceHeroTwo,
    image3: InsuranceHeroThree,
    image4: InsuranceHeroFour,
    image5: InsuranceHeroFive,
  },
];

export const ServiceItems = [
  {
    title: "Our Services",
    buttonText: "Learn more",
    buttonIcon: <HiOutlineArrowSmRight />,
    serviceItems: [
      {
        icon: Speed,
        title: "Fast Quotes",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        icon: Support,
        title: "Fast Quotes",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        icon: Reliable,
        title: "Fast Quotes",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        icon: Security,
        title: "Fast Quotes",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
    ],
  },
];

export const ProcessItems = [
  {
    title: "Our Services",
    subtitle: "How to get started with us",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Cras cursus ut fames vivamus leo praesent sagittis ultrices.`,
    buttonText: "Learn more",
    sectionImage: InsuranceHeroFive,
    processSteps: [
      { icon: Search, description: "Search for an insurance product" },
      { icon: Find, description: "Search for an insurance product" },
      { icon: GetQuote, description: "Search for an insurance product" },
      { icon: Save, description: "Search for an insurance product" },
    ],
  },
];

export const ProductItems = [
  {
    title: "Insurance products",
    subtitle: "Select an insurance product",
    buttonText: "View more",
    buttonIcon: <HiOutlineArrowSmRight />,
    categories: ["All", "Car", "Medical", "Business", "Poupular", "House"],
    insuranceProducts: [
      {
        image: InsuranceItemTwo,
        insuranceName: "lorem ipsum",
        amount: "Ksh.500.00",
        title: "AAR car insurance pay less get more",
        ratingCount: 5,
        primaryButtonText: "Get Quote",
        secondaryButtonText: "View Details",
      },
      {
        image: InsuranceItemOne,
        insuranceName: "lorem ipsum",
        amount: "Ksh.500.00",
        title: "AAR car insurance pay less get more",
        ratingCount: 5,
        primaryButtonText: "Get Quote",
        secondaryButtonText: "View Details",
      },
      {
        image: InsuranceItemThree,
        insuranceName: "lorem ipsum",
        amount: "Ksh.500.00",
        title: "AAR car insurance pay less get more",
        ratingCount: 5,
        primaryButtonText: "Get Quote",
        secondaryButtonText: "View Details",
      },
      {
        image: InsuranceItemFour,
        insuranceName: "lorem ipsum",
        amount: "Ksh.500.00",
        title: "AAR car insurance pay less get more",
        ratingCount: 5,
        primaryButtonText: "Get Quote",
        secondaryButtonText: "View Details",
      },
    ],
  },
];

export const AboutUsItems = [
  {
    title: "About us",
    subtitle: "What we do and how we do it",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Cras cursus ut fames vivamus leo praesent sagittis ultrices.`,
    buttonText: "Learn more",
    sectionImage: InsuranceItemOne,
  },
];

export const TestimonialsItems = [
  {
    title: "Testimonials",
    subtitle: "What our clients are saying",
    buttonText: "Read more",
    quoteIcon: Quote,
    testimonials: [
      {
        testimonial: `Lorem ipsum dolor sit amet, consectetur
          adipiscing elit.Cras cursus ut fames vivamus leo praesent sagittis ultrices.`,
        image: TestOne,
        person: "Jack Mwali",
      },
      {
        testimonial: `Lorem ipsum dolor sit amet, consectetur
          adipiscing elit.Cras cursus ut fames vivamus leo praesent sagittis ultrices.`,
        image: TestTwo,
        person: "Jack Mwali",
      },
      {
        testimonial: `Lorem ipsum dolor sit amet, consectetur
          adipiscing elit.Cras cursus ut fames vivamus leo praesent sagittis ultrices.`,
        image: TestThree,
        person: "Jack Mwali",
      },
    ],
  },
];

export const FaqsItems = [
  {
    title: "Frequently asked questions",
    subtitle: "Answers to  your questions to us",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Cras cursus ut fames vivamus leo praesent sagittis ultrices.`,
    primaryButtonText: "Contact us",
    secondaryButtonText: "View more",
    buttonIcon: <HiOutlineArrowSmRight />,
    faqIcon: Plus,
    faqs: [
      "How do I get started with you",
      "How do I get started with you",
      "How do I get started with you",
      "How do I get started with you",
    ],
  },
];

export const PreFooterItems = [
  {
    subtitle: (
      <p>
        Ready to{" "}
        <span className="inline-flex text-bimalink-blue"> secure </span> your
        future with our help!
      </p>
    ),
    buttonText: "Get quote",
  },
];

export const Socials = [
  { icon: <FaFacebook />, url: "https://www.facebook.com/" },
  { icon: <AiFillTwitterCircle />, url: "https://twitter.com/" },
  { icon: <AiFillInstagram />, url: "https://www.instagram.com/" },
  { icon: <FaLinkedin />, url: "https://linkedin.com/" },
];

export const AuthPagesItems = [
  {
    fName: "First Name",
    first: "first_name",
    lName: "Last Name",
    last: "last_name",
    email: "Email",
    eName: "email",
    pass: "Password",
    pName: "password",
    cpass: "Confirm Password",
    cName: "confirm_password",
    terms: "I agree to the Terms & Conditions",
    signUp: "Sign Up",
    signIn: "Sign In",
    gSignIn: "Google Login",
    gSignUp: "Sign up with Google",
    haveAcc: "Already have an account?",
    registerMessage: "Welcome Get an account",
    loginMessage: "Welcome Back Login",
    notHaveAcc: "Already have an account?",
    orgName: "Organization Name",
    oName: "organization",
    forgotPass: "Forgot your password ? ",
  },
];

export const onBoardingItems = [
  {
    client: {
      messageOne: "Hello 👋  Lets set up your profile",
      messageTwo: "Hello 👋  go on setting up your profile",
      screenOne: {
        title: "Date of Birth",
        desc: "This should match the date on your ID. Must be 18 years old or older.",
        fieldName: "Date of birth",
        type: "date",
        name: "date",
        buttonText: "Next",
      },
      screenTwo: {
        title: "National Identification Number ",
        desc: "You will use it when you’re getting your quotes to ensure easier processing.",
        fieldName: "Id number",
        buttonText: "Next",
        type: "normal",
        name: "idNumber",
        backIcon: ArrowBack,
      },
      screenThree: {
        title: "Mobile phone",
        desc: "We’ll use it to keep your account safe and provide support if you need it.",
        fieldName: "Mobile Phone Number",
        buttonText: "Next",
        type: "normal",
        name: "phone",
        backIcon: ArrowBack,
      },
      screenFour: {
        title: "Where do you live?",
        desc: "We need to verify your location. This is also to better serve you.",
        fieldName: "County",
        buttonText: "Next",
        type: "combo",
        options: [
          { value: "Nairobi" },
          { value: "Garissa" },
          { value: "Mombasa" },
          { value: "Kajiado" },
          { value: "Kericho" },
        ],
        name: "county",
        backIcon: ArrowBack,
      },
      screenFive: {
        title: "Please select your insurance?",
        desc: "We will use this to make sure you get the latest updates when made?",
        fieldName: "Insurance  Type",
        buttonText: "Next",
        type: "combo",
        options: [
          { value: "Jubilee" },
          { value: "AAR" },
          { value: "First Assurance" },
          { value: "Britam" },
          { value: "Equity" },
        ],
        name: "insurance",
        backIcon: ArrowBack,
      },
      screenSix: {
        title: "Please describe your self?",
        desc: "We will use this to make sure you get the best insurance products tailored for you?",
        fieldName: "Job Title",
        fieldNameOne: "Description",
        textArea: "textArea",
        buttonText: "Next",
        type: "normal",
        name: "desc",
        backIcon: ArrowBack,
      },
      screenSeven: {
        title: "Add your profile image",
        desc: "We will use this to make sure your profile is visible to insurance providers.",
        buttonText: "Next",
        backIcon: ArrowBack,
        hasChildren: true,
        image: true,
      },
      screenEight: {
        title: "A preview of your profile",
        buttonText: "Save and Continue to Dashboard",
        backIcon: ArrowBack,
        hasChildren: true,
      },
    },
    company: {},
  },
];

