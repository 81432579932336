import React from 'react'
import { twMerge } from 'tailwind-merge'
import Card from './Card'

const Table = ({className, children}) => {
    const classes = twMerge(`w-full flex p-[0px] overflow-auto flex-col gap-[10px] bg-white h-full ${className}`)
  return (
    <Card className={classes}>{children}</Card>
  )
}

export default Table