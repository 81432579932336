import React from "react";
import { twMerge } from "tailwind-merge";

const Card = ({ className, children, type, onSubmit, name }) => {
  const cardClass = twMerge(
    `flex  items-start flex-col bg-bimalink-blue-50 rounded-[16px] p-[25px] gap-[18px] ${className}`
  );

  return (
    <>
      {type === "form" ? (
        <form className={cardClass} name={name} onSubmit={onSubmit}>{children}</form>
      ) : (
        <div className={cardClass}>{children}</div>
      )}
    </>
  );
};

export default Card;
