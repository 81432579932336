import React, { useContext } from "react";

import Card from "./Card";
import FormField from "./FormField";
import Text from "./Text";
import { ReactComponent as SearchIcon } from "../../assets/images/SearchIcon.svg";
import { ReactComponent as Notification } from "../../assets/images/Notification.svg";
import Image from "./Image";
import Button from "./Button";
import { RiAddFill } from "react-icons/ri";
import User from "../../assets/images/testmonial1.png";
import Icon from "./Icon";
import { AuthContext } from "../../context/authContext";

const DashBoardTopNav = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Card
      className={
        " w-full flex-row py-[1px] justify-between items-center px-[0px] max-lg:hidden"
      }
    >
      <Card
        className={"p-[0px] bg-transparent gap-[0px] w-[30%] justify-center"}
      >
        <Text className={"text-[20px] gap-2"}>
          Welcome,
          <span className="text-bimalink-blue">
            {currentUser?.fullname?.split(" ")[0]}{" "}
          </span>
          👋
        </Text>
        <Text className={"text-[13px]"}>Its nice to see you again!</Text>
      </Card>
      <Card
        className={
          "p-[0px] bg-transparent gap-[15px] w-[90%] flex-row items-center justify-between"
        }
      >
        <FormField
          formContainerStyle="flex-row gap-[15px] items-center bg-white rounded-full px-[20px] py-[10px] mr-auto w-[50%]"
          iconOrComponent={<SearchIcon />}
          showButton={false}
          placeHolder="Search"
        />
        <Card className={"p-[0px] bg-transparent flex-row items-center"}>
          <Notification />
          <Icon className={"bg-transparent p-[0px]"}>
            <Image src={User} />
          </Icon>
        </Card>
        <Button
          className={"flex gap-[5px] hover:bg-bimalink-blue-50"}
          iconLeft={<RiAddFill className="text-[30px]" />}
          buttonText="Get Quote"
        />
      </Card>
    </Card>
  );
};

export default DashBoardTopNav;
