import React from 'react'
import { twMerge } from 'tailwind-merge';

const Text = ({children, className }) => {
    const classNames = twMerge(`flex items-center justify-center text-bimalink-blue-gray-600 font-bold ${className}`)
  return (
    <span className={classNames}>
        {children}
    </span>
  )
}

export default Text