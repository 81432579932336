import React from "react";
import { twMerge } from "tailwind-merge";

const Image = ({ src, alt, className }) => {
    const classImage = twMerge(`w-full h-full object-cover rounded-[10px] ${className}`);
  return (
    <>
      <img src={src} alt={alt} className={classImage} />
    </>
  );
};

export default Image;
