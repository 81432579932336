import React from "react";
import { TbChevronDown } from "react-icons/tb";

import Button from "./Button";
import FormField from "./FormField";

const SearchRight = () => {
  return (
    <>
      <Button
        className="text-bimalink-blue-gray-400 bg-bimalink-blue-gray-100 text-[13px] font-medium gap-[10px] px-[24px] py-[11px] min-w-[138px] max-sm:w-full max-sm:justify-between"
        buttonText={"Top rated"}
        buttonStyle="pastel"
        iconRight={<TbChevronDown />}
      />
      <div className="max-sm:hidden min-w-[.1rem] rounded-full bg-bimalink-blue-gray-100 h-[40px]"></div>
    </>
  );
};

const Search = () => {
  return (
    <>
      <FormField
        iconOrComponent={<SearchRight />}
        buttonText="Search"
        placeHolder={"Search for..."}
        formButtonStyle="gap-[18px] min-w-[178px] h-[52px] font-medium text-[16px] max-sm:w-full"
        formContainerStyle={"w-[80%] max-lg:w-[90%] py-[10px] max-sm:flex-col max-lg:rounded-[40px] mx-auto max-sm:gap-[20px] max-sm:px-[20px] max-sm:py-[16px] mt-[20px]"}
        formInputStyle="max-sm:w-full max-sm:border-solid max-sm:border-l-2 max-sm:pl-[20px] max-sm:py-[10px]"
      />
    </>
  );
};

export default Search;
