import React from 'react'
import Card from './Card'
import Text from './Text'

const DashBoardStats = ({ items }) => {

    const { icon, count, name, desc} = items;
  return (
    <Card className={"lg:max-w-[256px] max-lg:min-w-[256px] max-sm:max-w-full w-full bg-white rounded-[25px] gap-[5px]"}>
        {icon}
        <Card className={"p-[0px] flex-row items-center bg-transparent gap-[10px]"}>
            <Text className={"text-[20px] text-bimalink-blue-gray-700"}>
                {count}
            </Text>
            <Text>
                {name}
            </Text>
        </Card>
        <Text className={"text-[13px]"}>
            {desc}
        </Text>
    </Card>
  )
}

export default DashBoardStats