import React from "react";
import Button from "./Button";
import Card from "./Card";
import Image from "./Image";

const ProductCard = ({
  image,
  insuranceName,
  amount,
  title,
  ratingCount,
  primaryButtonText,
  secondaryButtonText,
  buttonIcon,
}) => {
  return (
    <Card className="p-[0px] pb-[15px] bg-white w-[277px]">
      <Image src={image} alt="Products" className={" h-[226px]"}/>
      <div className="px-[10px] flex flex-col gap-[10px]">
        <div className="flex justify-between items-center">
          <span className="rounded-full bg-bimalink-blue-gray-200 px-[20px] py-[6px] font-bold text-[12px] text-bimalink-green-400">{insuranceName}</span>
          <span className="text-bimalink-blue-gray-700 font-bold text-[15px]">{amount}</span>
        </div>

        <h2 className="text-bimalink-blue-gray-500 font-bold text-[15px] capitalize">{title}</h2>
        <div>
          <span className="text-bimalink-blue-gray-500 font-bold text-[15px] capitalize">({ratingCount})</span>
        </div>
        <div className="flex items-center justify-between text-[12px] font-medium">
          {primaryButtonText ? (
            <Button buttonText={primaryButtonText} className="h-[30px] min-w-[103px]"/>
          ) : null}
          {secondaryButtonText ? (
            <Button
              buttonStyle="text"
              buttonText={secondaryButtonText}
              iconRight={buttonIcon}
              className="gap-[5px]"
            />
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
