import React from "react";
import { twMerge } from "tailwind-merge";
import LogoImage from "../../assets/images/logo.png";
import Button from "./Button";
import Card from "./Card";
import Image from "./Image";

const Logo = ({ className, type = "normal", logoClass, to="/", onClick }) => {
  const styleDashboard = twMerge(
    `bg-white rounded-full w-[180px] h-[63px] px-[26px] py-[13px] flex items-center justify-center shadow-bimalink-shadow ${logoClass}`
  );
  const normal = twMerge(`p-[0px] bg-transparent rounded-none`);
  const classNames = {
    normal,
    dash: styleDashboard,
  };
  return (
    <Card className={classNames[type]}>
      <Button
        type="link"
        buttonIcon={true}
        iconLeft={<Image src={LogoImage} alt="Logo" />}
        to={to}
        className={className}
        onClick={onClick}
      />
    </Card>
  );
};

export default Logo;
