import React from 'react'
import { twMerge } from 'tailwind-merge'
import Text from './Text'

const Status = ({color, className, children}) => {
    const classNames = twMerge(`bg-bimalink-green-300 rounded-[10px] px-[26px] py-[7px] ${className} ${color}`)
  return (
    <Text className={classNames}>{children}</Text>
  )
}

export default Status