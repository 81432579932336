import React from "react";
import Card from "../../components/common/Card";
import Cell from "../../components/common/Cell";
import DashBoardStats from "../../components/common/DashBoardStats";
import DashProduct from "../../components/common/DashProduct";
import Header from "../../components/common/Header";
import Row from "../../components/common/Row";
import Status from "../../components/common/Status";
import Table from "../../components/common/Table";
import { dashBoardHomeItems } from "../../data";

const Dashboard = () => {
  const { top, bottom, side, sideBottom } = dashBoardHomeItems[0];

  return (
    <Card className={"w-full h-full p-[0px] mt-[20px] overflow-auto items-center mx-auto"}>
      <Card
        className={
          "p-[0px] max-sm:grid-cols-1 max-lg:grid max-lg:grid-cols-2 flex-row bg-transparent w-full gap-[20px] flex-wrap items-center mx-auto"
        }
      >
        {top.map((item, index) => (
          <DashBoardStats items={item} key={index + Math.random() * 3000} />
        ))}
      </Card>
      <Card
        className={
          "bg-transparent p-[0px] flex-row w-full h-full justify-between gap-[20px] max-lg:grid max-lg:grid-cols-1"
        }
      >
        <Card className={"bg-white w-full h-full flex-wrap"}>
          <Table className="overflow-auto">
            <Row type="header">
              {
                bottom[0].titles.map((item, index) => (
                  <Header key={index + Math.random()}>
                    {item}
                  </Header>
                ))
              }
            </Row>
            {bottom[0].items.map(({date, endDate, name, insurer, cost, duration, status}, index) => (
              <Row key={index + Math.random() * 1000}>
                <Cell>
                  {index + 1}
                </Cell>
                <Cell>
                  {date}
                </Cell>
                <Cell>
                  {endDate}
                </Cell>
                <Cell>
                  {name}
                </Cell>
                <Cell>
                  {insurer}
                </Cell>
                <Cell>
                  {cost}
                </Cell>
                <Cell>
                  {duration}
                </Cell>
                <Cell>
                  <Status>{status}</Status>
                </Cell>
              </Row>
            ))}
          </Table>
        </Card>
        <Card className={"bg-transparent min-w-[256px] max-lg:flex-row h-full p-[0px] max-sm:grid max-sm:grid-cols-1"}>
          <Card className={"bg-white w-full  p-[10px]"}>
            {
              side.map((item, index) => (
                <DashProduct key={index + Math.random() * 800} items={item} />
              ))
            }
          </Card>
          <Card className={"bg-white w-full p-[10px]"}>
          {
              sideBottom.map((item, index) => (
                <DashProduct key={index + Math.random() * 800} items={item} />
              ))
            }
          </Card>
        </Card>
      </Card>
    </Card>
  );
};

export default Dashboard;
