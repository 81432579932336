import React from 'react'
import { twMerge } from 'tailwind-merge'
import Card from './Card'
import Text from './Text'

const ProfileSection = ({title, titleClass, children, childrenContainerClass, className}) => {
  const childrenClass = twMerge(`bg-transparent p-[10px] w-full border border-solid border-bimalink-blue-gray-200 ${childrenContainerClass}`)
  const classTitle = twMerge(`text-bimalink-blue-gray-800 text-[18px] ${titleClass}`)

  const containerClass = twMerge(`bg-transparent p-0 w-full gap-2 ${className}`)
  return (
    <Card className={containerClass}>
      <Text className={classTitle}>
        {
          title
        }
      </Text>
      <Card className={childrenClass}>
        {children}
      </Card>
    </Card>
  )
}

export default ProfileSection