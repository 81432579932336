import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import { twMerge } from "tailwind-merge";

import LinkItem from "../LinkItem";
import Button from "./Button";
import Logo from "./Logo";
import { primaryNavItems } from "../../data";

const AuthPageButtons = ({ className }) => {
  const classes = twMerge(
    `flex flex-col lg:flex lg:flex-row lg:justify-between lg:items-center items-start lg:gap-[25px] gap-[1rem] ${
      className ?? ""
    }`
  );
  return (
    <ul className={classes}>
      <LinkItem to="/login">Sign In</LinkItem>
      <li>
        <Button
          type="link"
          to="/register"
          className="max-lg:px-[3rem] lg:px-[40px] lg:py-[16px] text-base font-medium"
          buttonText="Sign Up"
        />
      </li>
    </ul>
  );
};

const WebNavbar = () => {
  const [navShowing, setNavShowing] = useState(false);
  const [scroll, setScroll] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const showNavBar = () => {
    setNavShowing((prev) => !prev);
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <div
      className={`fixed right-0 left-0 -top-1 z-[900] ${
        scroll ? "bg-white shadow-md" : ""
      }`}
    >
      <div className="container flex gap-[1.5rem] font-medium justify-between items-center w-[90%] mx-auto py-[16px]">
        <Logo className={"max-lg:w-[150px] w-[170px]"} />
        <nav
          className={`z-[100] max-lg:absolute max-lg:top-0 max-lg:px-[2rem] max-lg:py-[1.3rem] max-lg:w-full max-lg:h-[100vh] max-lg:right-0 max-lg:bg-bimalink-blue-gray max-lg:backdrop-blur-3xl flex-col lg:flex lg:flex-row gap-[2rem] lg:items-center lg:static font-medium justify-start lg:justify-between ${
            navShowing ? "flex" : "hidden"
          }`}
        >
          <Button
            buttonIcon={true}
            iconLeft={<CgClose className="p-0 m-0" />}
            onClick={showNavBar}
            className="lg:hidden w-min text-bimalink-blue p-0 bg-transparent text-[2rem]"
          />
          <ul className="flex flex-col lg:flex lg:flex-row lg:justify-between lg:items-center items-start gap-[1rem] lg:gap-[30px]">
            {primaryNavItems.map(({ name, path }, index) => (
              <LinkItem key={path + index} to={path}>
                {name}
              </LinkItem>
            ))}
          </ul>
          <AuthPageButtons className="lg:hidden" />
        </nav>
        <AuthPageButtons className="max-lg:hidden" />
        <Button
          buttonIcon={true}
          iconLeft={<FiMenu />}
          onClick={showNavBar}
          className="lg:hidden w-max text-bimalink-blue px-0 py-0 bg-transparent text-[2rem]"
        />
      </div>
    </div>
  );
};

export default WebNavbar;
