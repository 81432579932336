import React, { useContext, useState } from "react";

import { AuthPagesItems } from "../data";
import Button from "./common/Button";
import Card from "./common/Card";
import FilterTabs from "./common/FilterTabs";
import FormField from "./common/FormField";
import Text from "./common/Text";
import AuthTitle from "./common/AuthTitle";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import Loader from "./common/Loader";

const AuthPage = ({ page, values }) => {
  const [insuree, setInsuree] = useState(true);

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    organization: "",
    insuree: false,
  });

  const [error, setError] = useState(
    ""
  );

  const { isLoading, login, signup } = useContext(AuthContext);

  const navigate = useNavigate();

  const {
    fName,
    first,
    lName,
    last,
    email,
    eName,
    pass,
    pName,
    cpass,
    cName,
    terms,
    signUp,
    signIn,
    gSignIn,
    gSignUp,
    haveAcc,
    notHaveAcc,
    orgName,
    oName,
    registerMessage,
    loginMessage,
    forgotPass,
  } = values;

  const tabHandler = () => {
    setInsuree((prev) => !prev);
    setInputs((prev) => ({ ...prev, insuree: !prev.insuree }));
  };

  const onChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const clearForm = () => {
    setInputs({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      organization: "",
      insuree: false,
    });
  };

  const clearError = () => {
    setError(
      ""
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (e.target.name === "register") {

      clearError();

      try {
        await signup(inputs);
        clearForm();
        navigate("/dashboard/home");

      } catch (error) {
        setError(error.response.data)
      }
      return;
    }

    clearError();

    try {
      await login(inputs);
      clearForm();
      navigate("/dashboard/home");
    } catch (error) {
      setError(error.response.data);
    }
  };
  return (
    <div className="py-[100px] w-[90%] mx-auto">
      {page === "register" ? (
        <Card className="bg-white p-[0px] w-full items-center justify-center">
          <AuthTitle>{registerMessage}</AuthTitle>
          <Card
            className={
              "w-full md:w-[667px] lg:w-[667px] py-[57px] mx-auto md:px-[70px] lg:px-[70px] gap-[35px] items-center"
            }
            type="form"
            onSubmit={onSubmit}
            name={page}
          >
            <Card className="w-full gap-[20px] items-center p-[0px]">
              <Text className="text-bimalink-blue-gray-800 text-[20px]">
                Sign up as
              </Text>
              <FilterTabs
                array={["Insuree", "Insurer"]}
                className="border-none max-w-max rounded-full p-[6px] gap-[13px]"
                onClick={tabHandler}
              />
            </Card>
            <Card className="sm:flex-row p-[0px] gap-[37px] md:gap-[20px] lg:gap-[20px] w-full bg-transparent">
              <FormField
                fieldName={insuree ? fName : orgName}
                name={insuree ? first : oName}
                inputType="normal"
                fieldType="normal"
                formContainerStyle={"w-full"}
                fieldRequired={true}
                onChange={onChange}
                value={insuree ? inputs.first_name : inputs.organization}
              />
              {insuree ? (
                <FormField
                  fieldName={lName}
                  name={last}
                  inputType="normal"
                  fieldType="normal"
                  formContainerStyle={"w-full"}
                  fieldRequired={true}
                  onChange={onChange}
                  value={inputs.last_name}
                />
              ) : null}
            </Card>
            <FormField
              type="email"
              fieldName={email}
              name={eName}
              inputType="normal"
              fieldType="normal"
              formContainerStyle={"w-full"}
              fieldRequired={true}
              onChange={onChange}
              value={inputs.email}
            />
            <Card className="sm:flex-row p-[0px] gap-[37px] md:gap-[20px] lg:gap-[20px] w-full bg-transparent">
              <FormField
                type="password"
                fieldName={pass}
                name={pName}
                inputType="normal"
                fieldType="normal"
                formContainerStyle={"w-full"}
                fieldRequired={true}
                onChange={onChange}
                value={inputs.password}
              />
              <FormField
                type="password"
                fieldName={cpass}
                name={cName}
                inputType="normal"
                fieldType="normal"
                formContainerStyle={"w-full"}
                fieldRequired={true}
                onChange={onChange}
                value={inputs.confirm_password}
              />
            </Card>
            <Card className="inline-flex flex-row items-center p-[0px] gap-[20px] w-full bg-transparent">
              <Button
                buttonForm="checkbox"
                type="input"
                className="h-[18px] w-[18px] rounded"
              />
              <Text className="text-[15px] font-semibold">{terms}</Text>
            </Card>
            {error && <Text className={"text-bimalink-red-500 self-start"}>{error}</Text>}
            <Button
              type="submit"
              iconRight={isLoading ? <Loader className={"w-[2rem] text-bimalink-blue"} /> : null}
              buttonText={signUp}
              className="w-full h-[60px] hover:bg-bimalink-blue-50"
            />
            <Card className="inline-flex flex-row items-center justify-center p-[0px] gap-[20px] w-full bg-transparent">
              <span className="h-[.1rem] w-[80px] md:w-[100px] lg:w-[100px] bg-bimalink-blue-gray-300"></span>
              <Text>Or</Text>
              <span className="h-[.1rem] w-[80px] md:w-[100px] lg:w-[100px] bg-bimalink-blue-gray-300"></span>
            </Card>
            {insuree && (
              <Button
                buttonText={gSignUp}
                className="w-full h-[60px] hover:bg-bimalink-blue-50"
              />
            )}
            <Button
              buttonText={haveAcc}
              className="text-bimalink-blue text-[18px] text-center"
              buttonStyle="text"
              type="link"
              to="/login"
            />
          </Card>
        </Card>
      ) : null}
      {page === "login" ? (
        <Card className="bg-white p-[0px] w-full items-center justify-center">
          <AuthTitle />
          <Card
            className={
              "w-full md:w-[667px] lg:w-[667px] py-[57px] mx-auto md:px-[70px] lg:px-[70px] gap-[35px] items-center"
            }
            type="form"
            name={page}
            onSubmit={onSubmit}
          >
            <Card className="w-full gap-[20px] items-center p-[0px]">
              <Text className="text-bimalink-blue-gray-600 max-sm:text-[25px] text-[30px] text-center">
                {loginMessage}
              </Text>
            </Card>
            <FormField
              type="email"
              fieldName={email}
              name={eName}
              inputType="normal"
              fieldType="normal"
              formContainerStyle={"w-full"}
              fieldRequired={true}
              onChange={onChange}
              value={inputs.email}
            />
            <FormField
              type="password"
              fieldName={pass}
              name={pName}
              inputType="normal"
              fieldType="normal"
              formContainerStyle={"w-full"}
              fieldRequired={true}
              onChange={onChange}
              value={inputs.password}
            />
            <Card className="inline-flex flex-row items-center p-[0px] gap-[20px] w-full bg-transparent">
              <Button
                type="link"
                buttonStyle="text"
                buttonText={forgotPass}
                className="text-[15px] font-semibold text-bimalink-blue-gray-500"
                to=""
              />
            </Card>
            {error && <Text className={"text-bimalink-red-500 self-start"}>{error}</Text>}
            <Button
              buttonText={signIn}
              iconRight={isLoading ? <Loader className={"w-[2rem] text-bimalink-blue"} /> : null}
              type="submit"
              className="w-full h-[60px] hover:bg-bimalink-blue-50"
            />
            <Card className="inline-flex flex-row items-center justify-center p-[0px] gap-[20px] w-full bg-transparent">
              <span className="h-[.1rem] w-[80px] md:w-[100px] lg:w-[100px] bg-bimalink-blue-gray-300"></span>
              <Text>Or</Text>
              <span className="h-[.1rem] w-[80px] md:w-[100px] lg:w-[100px] bg-bimalink-blue-gray-300"></span>
            </Card>

            <Button
              buttonText={gSignIn}
              className="w-full h-[60px] hover:bg-bimalink-blue-50"
            />
            <Button
              buttonText={notHaveAcc}
              type="link"
              to="/register"
              className="text-bimalink-blue text-[18px] text-center"
              buttonStyle="text"
            />
          </Card>
        </Card>
      ) : null}
    </div>
  );
};

const AuthPages = ({ page }) => {
  return (
    <div>
      <AuthPage page={page} values={AuthPagesItems[0]} />
    </div>
  );
};

export default AuthPages;
