import React from "react";
import Logo from "./Logo";
import Text from "./Text";

const AuthTitle = ({ children }) => {
  return (
    <>
      <Logo />
      <Text className="text-bimalink-blue-gray-600 max-sm:text-[25px] text-[30px] text-center max-sm:w-[90%]">
        {children}
      </Text>
    </>
  );
};

export default AuthTitle;
