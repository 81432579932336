import React from "react";
import { twMerge } from "tailwind-merge";

import Button from "./Button";
import Text from "./Text";
import ComboInput from "./ComboInput";

const FormField = ({
  formContainerStyle,
  type,
  fieldType = "all",
  name,
  value,
  onChange,
  formInputStyle,
  formButtonStyle,
  id,
  iconOrComponent,
  placeHolder,
  fieldName,
  buttonText,
  inputType,
  onClickButton,
  fieldRequired,
  error,
  showButton = true,
  options
}) => {
  const formContainer = twMerge(
    `flex px-[10px] py-[25px] items-center gap-[10px] border-2 border-solid focus:border-[2rem] rounded-full text-bimalink-blue-gray-500 ${
      formContainerStyle ?? ""
    }`
  );

  const normalContainer = twMerge(
    `flex justify-center gap-[10px] text-bimalink-blue-gray-500 flex-col ${
      formContainerStyle ?? ""
    }`
  );

  const formInput = twMerge(
    `appearance-none border-none focus:outline-none w-full text-[1rem] text-bimalink-blue-gray-700 ${
      formInputStyle ?? ""
    }`
  );

  const normalFormInput = twMerge(
    `appearance-none border-solid border-2 px-[20px] border-white focus:outline-bimalink-blue-200 bg-white focus:outline-none focus:bg-bimalink-blue-gray-50 w-full text-[1rem] text-bimalink-blue-gray-700 px-[20px] py-[10px] h-[62px] rounded-full ${
      formInputStyle ?? ""
    }`
  );

  const outlineInput = twMerge(
    `appearance-none border-solid border-2 px-[20px] border-bimalink-blue-gray-100 focus:outline-bimalink-blue-200 py-[10px] h-[62px] w-full text-[1.2rem] text-bimalink-blue-gray-700 rounded-full  ${
      formInputStyle ?? ""
    }`
  );

  const classNames = {
    container: formContainer,
    noOutline: formInput,
    outline: outlineInput,
    normal: normalFormInput,
    normalContainer,
  };

  return (
    <>
      {fieldType === "all" ? (
        <div
          className={classNames[!showButton ? "normalContainer" : "container"]}
        >
          {iconOrComponent}
          <input
            type={type ?? "text"}
            onChange={onChange}
            name={name}
            className={classNames["noOutline"]}
            placeholder={placeHolder ?? "Subscribe"}
            required={fieldRequired}
          />
          {showButton && (
            <Button
              type="submit"
              buttonText={buttonText ?? "Subscribe"}
              className={
                formButtonStyle ??
                "font-bold text-[12px] min-w-[100px] h-[34px]"
              }
            />
          )}
        </div>
      ) : (
        <div
          className={
            inputType ? classNames["normalContainer"] : classNames["container"]
          }
        >
          <label
            htmlFor={id}
            className="text-[15px] font-semibold text-bimalink-blue-gray-700"
          >
            {fieldName}
          </label>
          { type === "combo" ? <ComboInput options={options} onChange={onChange} selected={value} />: (type === "textarea" ? (
            <textarea
              type={type ?? "text"}
              onChange={onChange}
              name={name}
              className={classNames[inputType]}
              id={id}
              placeholder={placeHolder ?? ""}
              required={fieldRequired}
              value={value}
            ></textarea>
          ) : (
            <input
              type={type ?? "text"}
              onChange={onChange}
              name={name}
              className={classNames[inputType]}
              id={id}
              placeholder={placeHolder ?? ""}
              required={fieldRequired}
              value={value}
            />
          ))}
          {error && (
            <div className="self-start mt-[5px]">
              <Text className={"text-bimalink-red-500 text-left text-[13px]"}>{error}</Text>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FormField;
