import React, { useContext } from "react";
import { FiEdit } from "react-icons/fi";

import { AuthContext } from "../../context/authContext";
import ProfileCover from "../../assets/images/CoverProfile.jpg";
import Card from "./Card";
import Icon from "./Icon";
import Image from "./Image";
import ProfileSectionTitle from "./ProfileSection";
import Text from "./Text";
import Button from "./Button";
import ProfileSection from "./ProfileSection";
import { ProfileItems } from "../../data";
import Chip from "./Chip";

const UserProfile = ({ inputValues }) => {
  const { insuranceSection, aboutSection, personalSection, icons } =
    ProfileItems[0];
  const [Id, Phone, Email, Location] = icons;

  const { currentUser } = useContext(AuthContext);

  return (
    <Card className={"p-[0px] w-full h-full bg-white"}>
      <Card className={"p-0 bg-transparent w-full"}>
        <Card className={"w-full flex-row h-[40vh] p-0"}>
          <Image src={ProfileCover} className="rounded-b-none max-w-full" />
        </Card>
        <Card
          className={
            "p-0 bg-transparent w-full h-full flex-row max-sm:flex-col max-sm:items-start items-center  px-[20px] "
          }
        >
          <Icon
            className={
              "bg-white rounded-[50%] min-h-[120px] min-w-[120px] -mt-[60px]"
            }
          >
            <Image className={"rounded-[50%]"} src={inputValues.file} />
          </Icon>
          <Card
            className={"p-0 bg-transparent w-full h-full gap-[5px] max-sm:pb-[0px] pb-[15px]"}
          >
            <Text className={"text-bimalink-blue-gray-900 text-[18px]"}>
              {currentUser?.fullname ?? "Josh Herman"}
            </Text>
            <Text className={"text-[13px]"}>{inputValues.desc}</Text>
          </Card>
          <Button
            className={"gap-2"}
            buttonText={"Edit"}
            iconRight={<FiEdit />}
            disabled={true}
          />
        </Card>
        <Card className={"p-0 bg-transparent w-full flex-row px-[20px] pb-[20px] max-lg:flex-col"}>
          <Card className={"p-0 bg-transparent w-[60%] max-lg:w-full"}>
            <ProfileSection title="Description">
              <ProfileSection
                title={aboutSection}
                titleClass={"text-bimalink-blue-gray-700"}
                childrenContainerClass={"border-none p-0"}
              >
                <Text className={"text-[15px] font-normal"}>
                  {inputValues.textArea}
                </Text>
              </ProfileSection>
            </ProfileSection>
            <ProfileSection
              title={insuranceSection}
              childrenContainerClass="flex-wrap"
            >
              <Chip
                className={"gap-2"}
                chipName={inputValues.insurance.value}
                disabled={true}
              />
            </ProfileSection>
          </Card>
          <ProfileSection title="Personal" className={"w-[40%] max-lg:w-full"}>
            <Card className={"p-0 w-full bg-transparent"}>
              <Card className={"p-0 w-full bg-transparent flex-row"}>
                {Id}{" "}
                <Text className={"text-bimalink-blue-gray-500 font-normal text-[13px]"}>
                  {inputValues.idNumber}
                </Text>
              </Card>
              <Card className={"p-0 w-full bg-transparent flex-row"}>
                {Phone}{" "}
                <Text className={"text-bimalink-blue-gray-500 font-normal text-[13px]"}>
                  {inputValues.phone}
                </Text>
              </Card>
              <Card className={"p-0 w-full bg-transparent flex-row"}>
                {Email}{" "}
                <Text className={"text-bimalink-blue-gray-500 font-normal text-[13px]"}>
                  {inputValues.idNumber}
                </Text>
              </Card>
              <Card className={"p-0 w-full bg-transparent flex-row items-center"}>
                {Location}{" "}
                <Text className={"text-bimalink-blue-gray-500 font-normal text-[13px]"}>
                  {inputValues.county.value}
                </Text>
              </Card>
            </Card>
          </ProfileSection>
        </Card>
      </Card>
    </Card>
  );
};

export default UserProfile;
