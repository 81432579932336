import React from "react";
import { TfiEmail } from "react-icons/tfi";

import LinkItem from "../LinkItem";
import Logo from "./Logo";
import { primaryNavItems, Socials } from "../../data";
import FormField from "./FormField";
import Button from "./Button";

const WebFooter = () => {
  const subcribeHandler = (e) => {
    e.preventDefault();
  };
  return (
    <div className="container w-[90%] mx-auto text-[16px] font-semibold text-bimalink-blue-gray-600">
      <div className="flex items-start flex-col md:flex-wrap lg:flex-row gap-[1.5rem] font-medium lg:justify-between w-full py-[40px] border-solid border-t border-bimalink-blue-gray-300 border-b max-lg:items-center max-lg:text-center mx-auto">
        <div className="flex flex-col gap-[10px] max-lg:items-center max-lg:text-center max-lg:mx-auto">
          <Logo />
          <p className="w-[259px] leading-7">
            Connecting insurance providers to insurance seekers
          </p>
        </div>
        <div className="flex flex-col gap-[10px] max-lg:items-center max-lg:text-center max-lg:mx-auto">
          <h2 className="text-[19px] font-semibold text-black">Quick Links</h2>
          <ul className="flex flex-col items-start gap-[8px] max-lg:items-center max-lg:text-center">
            {primaryNavItems.map(({ name, path }, index) => (
              <LinkItem type="link" key={path + index} to={path}>
                {name}
              </LinkItem>
            ))}
          </ul>
        </div>
        <div className="flex flex-col gap-[10px] max-lg:items-center max-lg:text-center max-lg:mx-auto">
          <h2 className="text-[19px] font-semibold text-black">Socials</h2>
          <ul className="flex items-start gap-[8px] max-lg:mx-auto">
            {Socials.map(({ icon, url }, index) => (
              <li key={url + index}>
                <a
                  href={url}
                  className={
                    "flex items-center justify-center text-bimalink-blue text-[23px] w-[43px] h-[43px] border-solid rounded-full p-[10px] border-bimalink-blue-gray-300 border-2"
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col gap-[10px] max-lg:items-center max-lg:text-center">
          <h2 className="text-[19px] font-semibold text-black">Subscribe</h2>
          <p className="w-[259px] leading-7">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          </p>
          <form onSubmit={subcribeHandler}>
            <FormField
              type="email"
              placeholder="Subscribe"
              buttonText="Subscribe"
              formContainerStyle={"h-[60px]"}
              iconOrComponent={<TfiEmail className="text-[2.5rem]" />}
              fieldRequired={true}
            />
          </form>
        </div>
      </div>
      <div className="flex items-center justify-center py-[10px] text-[15px] font-medium max-sm:flex-col gap-[10px] max-lg:items-center">
        <Button type="link" buttonStyle="text" className="text-bimalink-blue-gray-400 font-medium" buttonText={"© 2022 BimaLink"}/>
        <Button type="link" buttonStyle="text" className="border-solid border-r-[1px] border-bimalink-blue-gray-400 border-l-[1px] px-[10px] text-bimalink-blue-gray-400 font-medium" buttonText={"Terms of Use"}/>
        <Button type="link" buttonStyle="text" className="text-bimalink-blue-gray-400 font-medium" buttonText={"Privacy Policy"}/>
      </div>
    </div>
  );
};

export default WebFooter;
