import React, { useState } from "react";

import AuthContainer from "../../components/common/AuthContainer";
import OnBoardingDetails from "../../components/common/OnBoardingDetails";
import SelectImage from "../../components/common/SelectImage";
import UserProfile from "../../components/common/UserProfile";
import { onBoardingItems } from "../../data";
import Profile from '../../assets/images/profile.png'

const OnBoarding = () => {
    const [screen, setScreen] = useState(0)
    const [inputValues, setInputValues] = useState({
      date: "",
      idNumber: "",
      phone: "",
      county: {value: "Select County"},
      insurance: {value: "Select Insurance"},
      desc: "",
      textArea: "",
      file: Profile,
    });
    const {messageOne, messageTwo, ...screens} = onBoardingItems[0].client;
    const currentScreen = Object.keys(screens)[screen]

    const moveNext = () => {
      setScreen(prev => prev + 1)
    }

    const moveBack = () => {
      setScreen(prev => prev - 1)
    }
  return (
    <>
      <AuthContainer className={screen === 7 ? "container mx-auto lg:w-[80%]" : ""} title={ screen === 0 ? messageOne: messageTwo}>
        <OnBoardingDetails items={screens[currentScreen]} onClick={moveNext} backHandler={moveBack} inputValues={inputValues} setInputValues={setInputValues}>
          {screen === 6 ? <SelectImage inputValues={inputValues} setInputValues={setInputValues} /> : null}
          {screen === 7 ? <UserProfile inputValues={inputValues} /> : null}
        </OnBoardingDetails>
      </AuthContainer>
    </>
  );
};

export default OnBoarding;
