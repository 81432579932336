import React, { useRef } from "react";
import { BsFillCameraFill } from "react-icons/bs";

import Image from "./Image";
import Button from "./Button";
import Card from "./Card";

const SelectImage = ({inputValues, setInputValues}) => {

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = URL.createObjectURL(event.target.files[0]);
    setInputValues((prev) => ({ ...prev, file: fileUploaded }));
  };
  return (
    <Card
      className={
        "w-[40%] mx-auto p-[0px] bg-black items-center justify-center relative h-[160px]"
      }
    >
      {inputValues.file && <Image className={"absolute inset-0 rounded-[16px]"} src={inputValues.file} alt="User File" />}
      <Card className={
        "w-full mx-auto p-[0px] bg-black/50 items-center justify-center relative h-[160px]"
      }>
        <Button
          buttonStyle="text"
          className={"text-white text-[34px] z-[10]"}
          onClick={handleClick}
          iconRight={<BsFillCameraFill />}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </Card>
    </Card>
  );
};
export default SelectImage;
