import axios from "axios";
import { isEmpty } from "lodash";

import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [isLoading, setIsLoading] = useState(null)

  const login = async (inputs) => {
    setIsLoading(true)
    const res = await axios.post("/signin", inputs);
    if (!isEmpty(res.data))
      setCurrentUser(res.data);
    setIsLoading(false)
  };

  const signup = async (inputs) => {
    setIsLoading(true)
    const res = await axios.post("/signup", inputs)
    if (!isEmpty(res.data))
      setCurrentUser(res.data);
    setIsLoading(false)
  }

  const logout = async () => {
    await axios.post("/logout");
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{isLoading, currentUser, login, signup, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
