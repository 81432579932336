import React from "react";
import { twMerge } from "tailwind-merge";
import Button from "./Button";

const Section = ({
  decoIcon,
  subtitle,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  buttonStyle,
  children,
  containerStyle,
  rightSectionStyle,
  leftSectionStyle,
  secondaryButtonClass,
  primaryButtonClass,
  primaryButtonIcon,
  secondaryButtonIcon,
  buttonBelow,
  sectionSubTitle,
  buttonContStyle,
  descriptionStyle,
  sectionBackground,
  sectionBackgroundStyle,
}) => {
  const contStyle = twMerge(
    `container flex mx-auto py-[100px] relative ${containerStyle}`
  );
  const leftStyle = twMerge(
    `flex flex-col gap-[18px] text-[16px] font-regular ${leftSectionStyle}`
  );
  const rightStyle = twMerge(`flex  ${rightSectionStyle}`);

  const sectSubTitle = twMerge(
    `flex items-center font-bold text-[35px] text-black w-[80%] relative ${sectionSubTitle}`
  );

  const buttonContainerStyle = twMerge(
    `flex gap-[10px] max-sm:flex-col items-start ${buttonContStyle}`
  );

  const sectDescStyle = twMerge(
    `flex flex-col gap-[20px] md:w-[80%] ${descriptionStyle}`
  );

  return (
    <div className={contStyle}>
      {sectionBackground && <>{sectionBackground}</>}
      <div className={leftStyle}>
        {title ? (
          <h2 className={"text-bimalink-blue text-[25px] font-bold"}>
            {title}
          </h2>
        ) : null}
        {subtitle ? (
          <h1 className={sectSubTitle}>
            {decoIcon ? (
              <span className="-top-[60px] left-[10px] lg:left-[-10px] md:left-[-80px] absolute">
                {decoIcon}
              </span>
            ) : null}
            {subtitle}
          </h1>
        ) : null}
        {description ? (
          <div className={sectDescStyle}>{description}</div>
        ) : null}
        <div className={buttonContainerStyle}>
          {primaryButtonText && !buttonBelow ? (
            <Button
              buttonText={primaryButtonText}
              className={primaryButtonClass}
              iconRight={primaryButtonIcon}
            />
          ) : null}
          {secondaryButtonText && !buttonBelow ? (
            <Button
              buttonStyle={buttonStyle}
              className={secondaryButtonClass}
              buttonText={secondaryButtonText}
              iconRight={secondaryButtonIcon}
            />
          ) : null}
        </div>
      </div>
      <div className={rightStyle}>{children}</div>
      {buttonBelow ? (
        <div>
          <Button
            buttonText={primaryButtonText}
            className={primaryButtonClass}
            iconRight={primaryButtonIcon}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Section;
