import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Button from './Button'
import Card from './Card'

const FilterTabs = ({array, className, onClick}) => {
  const [clicked, setClicked] = useState(false);
  const classNames = twMerge(`flex-row flex-wrap items-center justify-center mx-auto gap-[10px] gap-y-5 p-[10px] bg-white border-solid border-[1px] border-bimalink-blue-gray-200 md:rounded-full lg:rounded-full max-sm:w-[90%] ${className}`);
  const tabClicked = (e) => {
    setClicked(prev => !prev)
    if (onClick) onClick(e);
  }
  return (
    <Card className={classNames}>
        {array.map((item, index) => (
            <Button key={item + index} buttonText={item} buttonStyle={"pastel"} onClick={tabClicked} clicked={index === 0 ? !clicked : clicked}/>
        ))}
    </Card>
  )
}

export default FilterTabs