import React from 'react'
import AuthPages from '../../components/AuthPages'

const Login = () => {
  return (
    <div>
      <AuthPages page="login"/>
    </div>
  )
}

export default Login