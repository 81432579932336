import React from 'react'
import { SpinningCircles } from 'react-loading-icons'
import { twMerge } from 'tailwind-merge'

import Card from './Card'
import Text from './Text'

const Loader = ({className, containerClass, message}) => {
    const classes = twMerge(`w-[5rem] flex  items-center ${className}`)
    const containerClasses = twMerge(`bg-transparent rounded-none p-[0px] items-center justify-center ${containerClass}`)
  return (
    <Card className={containerClasses}>
        <Text className={classes}>
            <SpinningCircles fill="currentColor" />
        </Text>
        {message && <Text>
            {message}
        </Text>}
    </Card>
  )
}

export default Loader