import React from "react";
import Card from "../../components/common/Card";
import FilterTabs from "../../components/common/FilterTabs";
import Icon from "../../components/common/Icon";
import Image from "../../components/common/Image";
import ProductCard from "../../components/common/ProductCard";
import Search from "../../components/common/Search";
import Section from "../../components/common/Section";
import {
  AboutUsItems,
  FaqsItems,
  HeroItems,
  PreFooterItems,
  ProcessItems,
  ProductItems,
  ServiceItems,
  TestimonialsItems,
} from "../../data";

export const HeroDetailsSection = ({ description }) => {
  return (
    <>
      <p className="w-[60%] text-center">{description}</p>
      <Search className="mt-[20px]" />
    </>
  );
};

export const HeroBackground = ({ images }) => {
  const { image1, image3, image4, image5, homeDeco } = images;
  return (
    <>
      <div className="absolute lg:top-[90px]  right-[5%] left-[5%] z-[-2] flex justify-center  mx-auto">
        {homeDeco}
      </div>
      <Image
        src={image1}
        alt="insurance 1"
        className="max-lg:hidden absolute z-[-1] top-[240px] left-[50px] w-[220px] h-[120px] rounded-full"
      />
      <Image
        src={image5}
        alt="insurance 2"
        className="max-lg:hidden absolute z-[-1] top-[440px] left-[-2px] w-[180px] h-[180px] rounded-full"
      />
      <Image
        src={image4}
        alt="insurnace 3"
        className="max-lg:hidden absolute z-[-1] top-[678px] right-[100px] w-[140px] h-[140px] rounded-full"
      />
      <Image
        src={image3}
        alt="insurnace 3"
        className="max-lg:hidden absolute z-[-1] top-[340px] right-[-160px] w-[348px] h-[200px] rounded-full"
      />
    </>
  );
};

export const HeroSection = () => {
  return (
    <div>
      {HeroItems.map(
        (
          {
            subtitle,
            description,
            primaryButtonText,
            secondaryButtonText,
            homeDeco,
            curlyDeco,
            image1,
            image2,
            image3,
            image4,
            image5,
          },
          index
        ) => (
          <Section
            decoIcon={curlyDeco}
            subtitle={subtitle}
            description={<HeroDetailsSection description={description} />}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            buttonStyle="inverse"
            key={"key" + index}
            leftSectionStyle={
              "max-lg:w-[100%] lg:w-[90%] mx-auto gap-[9px]"
            }
            sectionSubTitle="text-[50px] max-sm:text-[35px] text-center mx-auto w-[70%] capitalize"
            buttonContStyle={
              "justify-center mt-[20px] gap-[38px] max-sm:flex-col-reverse items-center"
            }
            descriptionStyle="mx-auto items-center mt-[10px] w-full"
            primaryButtonClass={"w-[208px] max-sm:w-[40%] h-[62px]"}
            secondaryButtonClass={"w-[208px] max-sm:w-[50%] h-[62px]"}
            rightSectionStyle="w-[90%] lg:w-[50%] mx-auto max-sm:h-[198px] h-[316px]"
            containerStyle={"sm:w-[100%] flex-col gap-[51px] mt-[70px]"}
            sectionBackground={
              <HeroBackground
                images={{ image1, image3, image4, image5, homeDeco }}
              />
            }
          >
            <Image src={image2} alt="insurance" className="rounded-[200px]" />
          </Section>
        )
      )}
    </div>
  );
};

export const ServicesSection = () => {
  return (
    <div>
      {ServiceItems.map(
        ({ title, buttonText, buttonIcon, serviceItems }, index) => (
          <Section
            subtitle={title}
            sectionSubTitle="w-[50%] max-lg:justify-center"
            key={index}
            buttonStyle="text"
            secondaryButtonText={buttonText}
            secondaryButtonIcon={buttonIcon}
            secondaryButtonClass="text-[1.2rem]"
            leftSectionStyle={
              "max-lg:w-full max-lg:items-center max-lg:text-center max-lg:mx-auto"
            }
            rightSectionStyle={"gap-[20px] flex-wrap max-lg:justify-center"}
            containerStyle="w-[90%] bg-bimalink-blue-50 rounded-[30px] gap-[20px] justify-center px-[33px] max-lg:flex-col"
          >
            {serviceItems.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                className="lg:w-[210px] h-[208px] bg-white py-[5px] justify-center md:w-[300px]"
              >
                <Icon className={"w-[50px] h-[50px]"}>
                  <Image
                    src={icon}
                    alt="icon"
                    className={"object-contain w-[30px]"}
                  />
                </Icon>
                <h2 className="text-[15px] font-semibold">{title}</h2>
                <p className="text-[13px]">{description}</p>
              </Card>
            ))}
          </Section>
        )
      )}
    </div>
  );
};

export const OurProcessSection = () => {
  return (
    <div>
      {ProcessItems.map(
        (
          {
            title,
            subtitle,
            description,
            buttonText,
            sectionImage,
            processSteps,
          },
          index
        ) => (
          <Section
            title={title}
            subtitle={subtitle}
            description={description}
            primaryButtonText={buttonText}
            key={index}
            secondaryButtonClass="text-[1.2rem]"
            leftSectionStyle={
              "w-[45%] max-lg:w-full max-lg:items-center max-lg:text-center max-lg:mx-auto"
            }
            sectionSubTitle="max-lg:justify-center"
            rightSectionStyle={
              "lg:w-[50%] max-lg:items-center max-lg:justify-center max-lg:w-full max-lg:mx-auto"
            }
            containerStyle="w-[90%] items-center justify-center max-lg:flex-col-reverse flex-row-reverse justify-between my-[80px] max-lg:gap-[150px] "
            descriptionStyle={"md:w-[80%]"}
            primaryButtonClass={"w-[208px] gap-[8px] h-[62px]"}
          >
            <div className="relative md:h-[280px] lg:h-[280px] w-full flex max-auto items-center justify-center max-sm:px-[33px]">
              <Image
                src={sectionImage}
                alt="Our Process Section"
                className={
                  "rounded-[20px] absolute top-0 bottom-0 right-0 left-0 z-[-1]"
                }
              />
              <div className="grid grid-cols-2 gap-[20px] -my-[80px] mx-auto max-sm:flex max-sm:flex-wrap place-content-center">
                {processSteps.map(({ icon, description }, index) => (
                  <Card
                    key={index}
                    className="lg:w-[206px] h-[208px] py-[5px] justify-center rounded-[30px]"
                  >
                    <Icon
                      className={
                        "w-[30px] h-[30px] bg-bimalink-blue text-white"
                      }
                    >
                      {index + 1}
                    </Icon>
                    <Icon>
                      <Image
                        src={icon}
                        alt="icon"
                        className={"object-contain w-[30px]"}
                      />
                    </Icon>
                    <p className="text-[15px] font-semibold">{description}</p>
                  </Card>
                ))}
              </div>
            </div>
          </Section>
        )
      )}
    </div>
  );
};

export const InsuranceProductsSection = () => {
  return (
    <div className="bg-bimalink-blue-50">
      {ProductItems.map(
        (
          {
            title,
            subtitle,
            buttonText,
            buttonIcon,
            categories,
            insuranceProducts,
          },
          index
        ) => (
          <Section
            title={title}
            subtitle={subtitle}
            description={<FilterTabs array={categories} />}
            primaryButtonText={buttonText}
            buttonBelow={true}
            primaryButtonIcon={buttonIcon}
            key={index}
            containerStyle={
              "w-[90%] flex flex-col items-center w-full gap-[30px]"
            }
            leftSectionStyle={
              "md:w-[100%] lg:w-[90%] mx-auto gap-[9px] text-center"
            }
            sectionSubTitle="text-center mx-auto w-[90%] justify-center"
            descriptionStyle={"w-full justify-center items-center mx-auto"}
            rightSectionStyle="flex items-center justify-center w-full mx-auto"
            primaryButtonClass={"w-[208px] gap-[8px] h-[62px] mt-[20px]"}
          >
            <div className="flex gap-[30px] flex-wrap place-content-center">
              {insuranceProducts.map(
                (
                  {
                    image,
                    insuranceName,
                    amount,
                    title,
                    ratingCount,
                    primaryButtonText,
                    secondaryButtonText,
                  },
                  index
                ) => (
                  <ProductCard
                    key={index}
                    image={image}
                    insuranceName={insuranceName}
                    amount={amount}
                    title={title}
                    ratingCount={ratingCount}
                    primaryButtonText={primaryButtonText}
                    secondaryButtonText={secondaryButtonText}
                    buttonIcon={buttonIcon}
                  />
                )
              )}
            </div>
          </Section>
        )
      )}
    </div>
  );
};

export const AboutUs = () => {
  return (
    <div>
      {AboutUsItems.map(
        ({ title, subtitle, description, buttonText, sectionImage }, index) => (
          <Section
            title={title}
            subtitle={subtitle}
            description={description}
            primaryButtonText={buttonText}
            key={title + index}
            leftSectionStyle={
              "w-[45%] max-lg:w-full max-lg:items-center max-lg:text-center max-lg:mx-auto"
            }
            sectionSubTitle="max-lg:justify-center"
            rightSectionStyle={
              "lg:w-[50%] max-lg:items-center max-lg:justify-center max-lg:w-full max-lg:mx-auto"
            }
            containerStyle="w-[90%] justify-center items-center max-lg:flex-col flex-row justify-between mt-[80px] max-lg:gap-[150px]"
            descriptionStyle={"md:w-[80%]"}
            buttonContStyle="text-[16px] font-medium mt-[20px] gap-[38px] items-center h-[62px]"
            primaryButtonClass={"h-[62px] w-[208px]"}
          >
            <Image
              className="h-[400px] rounded-[30px]"
              src={sectionImage}
              alt="About Us"
            />
          </Section>
        )
      )}
    </div>
  );
};

export const TestimonialsSection = () => {
  return (
    <div className="bg-bimalink-blue-50">
      {TestimonialsItems.map(
        (
          {
            title,
            subtitle,
            description,
            buttonText,
            buttonIcon,
            quoteIcon,
            testimonials,
          },
          index
        ) => (
          <Section
            title={title}
            subtitle={subtitle}
            primaryButtonText={buttonText}
            key={title + index}
            buttonBelow={true}
            containerStyle={
              "flex flex-col items-center w-full gap-[20px] w-[90%]"
            }
            leftSectionStyle={
              "md:w-[100%] lg:w-[90%] mx-auto gap-[9px] text-center"
            }
            sectionSubTitle="text-center mx-auto w-[90%] justify-center"
            descriptionStyle={"w-full justify-center items-center mx-auto"}
            rightSectionStyle="flex items-center justify-center w-full mx-auto gap-[50px] flex-wrap"
            primaryButtonClass={"w-[208px] gap-[8px] h-[62px] mt-[30px]"}
          >
            {testimonials.map(({ testimonial, image, person }) => (
              <Card
                className="text-[16px] items-center bg-white w-[320px]"
                key={Math.random() * 1000}
              >
                <Icon styleType="fill" className={"w-[40px]"}>
                  <Image
                    src={quoteIcon}
                    alt="quote"
                    className="object-contain"
                  />
                </Icon>
                <p className="text-center">{testimonial}</p>
                <Icon styleType="fill">
                  <Image
                    src={image}
                    alt={person}
                    className="object-contain rounded-full h-[68px] w-[68px]"
                  />
                </Icon>
                <h2 className="font-bold">{person}</h2>
              </Card>
            ))}
          </Section>
        )
      )}
    </div>
  );
};

export const FaqsSection = () => {
  return (
    <div>
      {FaqsItems.map(
        (
          {
            title,
            subtitle,
            description,
            primaryButtonText,
            secondaryButtonText,
            buttonIcon,
            faqIcon,
            faqs,
          },
          index
        ) => (
          <Section
            title={title}
            subtitle={subtitle}
            description={description}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonIcon={buttonIcon}
            buttonStyle="text"
            key={Math.random() * 3000}
            leftSectionStyle={
              "w-[45%] max-lg:w-full max-lg:items-center max-lg:text-center max-lg:mx-auto"
            }
            sectionSubTitle="max-lg:justify-center"
            rightSectionStyle={
              "lg:w-[50%] flex-col max-lg:items-center max-lg:justify-center max-lg:w-full max-lg:mx-auto gap-[20px]"
            }
            containerStyle="w-[90%] justify-center max-lg:flex-col flex-row justify-between mt-[80px] max-lg:gap-[100px] "
            descriptionStyle={"md:w-[80%]"}
            buttonContStyle=" text-[16px] font-medium mt-[20px] gap-[38px] items-center"
            primaryButtonClass={"h-[62px] w-[208px]"}
            secondaryButtonClass="gap-[5px]"
          >
            {faqs.map((item, index) => (
              <div
                className="bg-bimalink-blue-50 py-[18px]  flex items-center rounded-full px-[31px] justify-between h-[63px] w-full"
                key={Math.random() * 400}
              >
                <p className="text-[22px] text-bimalink-blue-gray-600 font-bold  max-sm:text-[18px]">
                  {item}
                </p>
                <Icon styleType="fill">
                  <Image
                    src={faqIcon}
                    alt="Plus"
                    className="w-[15.5px] h-[15.5px]"
                  />
                </Icon>
              </div>
            ))}
          </Section>
        )
      )}
    </div>
  );
};

export const PreFooterSection = () => {
  return (
    <div>
      {PreFooterItems.map(({ subtitle, buttonText }) => (
        <Section
          key={Math.random() * 150}
          containerStyle="gap-[0px] mx-auto w-[90%]"
          subtitle={subtitle}
          sectionSubTitle={
            "w-[70%] max-lg:w-full text-[40px] max-lg:text-[30px] justify-center"
          }
          primaryButtonText={buttonText}
          primaryButtonClass="h-[62px] w-[208px]"
          rightSectionStyle={"hidden"}
          leftSectionStyle={
            "mx-auto max-lg:w-full max-lg:items-center max-lg:text-center max-lg:mx-auto flex-row items-center justify-between bg-bimalink-blue-50 max-lg:px-[21px] py-[77px] px-[127px] rounded-[30px] max-lg:flex-col max-lg:justify-center"
          }
        />
      ))}
    </div>
  );
};

const Home = () => {
  return (
    <>
      <HeroSection />
      <ServicesSection />
      <OurProcessSection />
      <InsuranceProductsSection />
      <AboutUs />
      <TestimonialsSection />
      <FaqsSection />
      <PreFooterSection />
    </>
  );
};

export default Home;
