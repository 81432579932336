import React, { useContext } from 'react'
import LinkItem from '../LinkItem'
import Card from './Card'
import Logo from './Logo'
import { dashBoardNavItems } from '../../data'
import { ReactComponent as Logout } from "../../assets/images/Logout.svg";
import { AuthContext } from '../../context/authContext'

const DashboardSideNav = () => {
    const { logout } = useContext(AuthContext);
  return (
    <Card className={'p-[0px] bg-transparent h-screen rounded-none pb-[20px] z-[10] py-[20px] w-[15%] max-lg:hidden'}>
        <Logo type='dash' logoClass={"self-center w-[90%]"} to="/dashboard/home"/>
        <ul className="flex flex-col mt-[50px] mb-auto gap-[5px] w-full">
            {dashBoardNavItems.map(({icon, name, path}, index) => (
                <LinkItem key={name + index} to={path} className="flex gap-[22px] rounded-l-[30px] rounded-r-[5px] py-[12px] px-[20px] border-r-solid border-r-[6px] border-r-transparent items-center hover:text-bimalink-blue" activeClassName={"flex gap-[22px] rounded-l-[30px] rounded-r-[5px] bg-bimalink-blue-gray-100 py-[12px] px-[20px] border-r-solid border-r-[6px] border-r-bimalink-blue items-center hover:border-bimalink-blue-900 -mr-[7px]"}>
                    {icon}
                    {name}
                </LinkItem>
            ))}
        </ul>
        <LinkItem onClick={logout} type="link" to={"/login"} className="flex gap-[22px] rounded-l-[30px] rounded-r-[5px] w-full py-[12px] px-[20px] border-r-solid border-r-[6px] border-r-transparent items-center hover:text-bimalink-blue ">
            <Logout />
            Logout
        </LinkItem>
    </Card>
  )
}

export default DashboardSideNav