import React from 'react'
import { twMerge } from 'tailwind-merge'

const Icon = ({className, children, styleType="normal"}) => {
  const fill = twMerge(`flex items-center justify-center w-[55px] h-[55px] bg-transparent rounded-full ${className}`);

  const normal = twMerge(`flex items-center justify-center w-[55px] h-[55px] rounded-full bg-bimalink-blue-100 p-[4px] ${className}`);

  const classNames = {
    fill,
    normal,
  }

  return (
    <div className={classNames[styleType]}>
        {children}
    </div>
  )
}

export default Icon