import React from 'react'
import {ImArrowLeft2} from 'react-icons/im'

import Button from './Button'
import Card from './Card'
import Text from './Text'

const Error = ({error, message}) => {
  return (
    <Card className={"h-screen rounded-none bg-white items-center justify-center"}>
        <Card className={"bg-white w-full max-sm:max-w-full max-w-[500px] items-center py-[50px]"}>
            <Text className={"text-[80px] leading-[60px] text-center"}>
                {error ?? 404}
            </Text>
            <Text className={"text-[20px] text-center"}>
                {message ?? "Oops! Page not found"}
            </Text>
            <Button to="/" type="link" className={"gap-[8px] w-[40%]"} iconLeft={<ImArrowLeft2 />} buttonText={"Go Home"}/>
        </Card>
    </Card>
  )
}

export default Error