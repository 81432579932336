import React from 'react'
import Card from './Card'
import Image from './Image.js'
import Icon from './Icon'
import Text from './Text'
import Button from './Button'

const DashProduct = ({items}) => {
    const {image, title, insurance, amount, count} = items;
  return (
    <Card className={"flex-row px-[12px] py-[8px] w-full h-full items-center gap-[10px] rounded-[10px]"}>
        {!amount && <Icon className={"p-[0px] w-[37px] h-[37px] rounded-full"}>
            <Image src={image} alt="product" className={"rounded-full"}/>
        </Icon>}
        <Card className={"p-[0px] rounded-none bg-transparent gap-[5px] mr-auto"}>
            <Text className={"text-[13px]"}>
                {title}
            </Text>
            <Card className={"p-[0px] rounded-none bg-transparent text-[10px] flex-row gap-[5px]"}>
                <Text>
                    {insurance}
                </Text>
                {amount ? "" : <Text>
                    <span>{count}</span> +
                </Text>}
                <Text>
                    {amount ? amount : "bought"}
                </Text>
            </Card>
        </Card>
        { amount && <Button buttonText={"View"} className="h-[29px] px-[10px] min-w-[63px] bg-bimalink-blue-400 border-bimalink-blue-400 hover:text-bimalink-blue-400 hover:border-bimalink-blue-400 rounded-[5px]" />}
    </Card>
  )
}

export default DashProduct