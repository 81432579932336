import React from 'react'
import Card from './Card'
import Text from './Text'

const ComingSoon = () => {
  return (
    <Card className={"h-screen gap-[10px] w-full rounded-none items-center justify-center"}><Text className={"text-[30px]"}>Coming Soon!</Text><Text className={"font-medium"}>Thank you</Text></Card>
  )
}

export default ComingSoon