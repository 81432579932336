import React from "react";
import { Link, NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const LinkItem = ({ children, to, type, className, activeClassName, onClick }) => {
  const active = twMerge(
    `text-bimalink-blue ${
      activeClassName ?? ""
    }`
  );

  const normal = twMerge(
    `text-bimalink-blue-gray-700 ${className ?? ""}`
  )
  return (
    <li className="list-none">
      {type === "link" ? (
        <Link
          to={to}
          className={normal}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : (
        <NavLink
          to={to}
          className={({ isActive }) =>
            isActive ? active : normal
          }
          onClick={onClick}
        >
          {children}
        </NavLink>
      )}
    </li>
  );
};

export default LinkItem;
