import React, { useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";

import WebFooter from "./components/common/WebFooter";
import WebNavbar from "./components/common/WebNavbar";
import Home from "./pages/website/Home";
import Products from "./pages/dashboard/Products";
import Blog from "./pages/website/Blog";
import AboutUs from "./pages/website/AboutUs";
import ContactUs from "./pages/website/ContactUs";
import Login from "./pages/website/Login";
import Register from "./pages/website/Register";
import OnBoarding from "./pages/website/OnBoarding";
import Dashboard from "./pages/dashboard/Dashboard";
import Card from "./components/common/Card";
import DashboardSideNav from "./components/common/DashboardSideNav";
import Covers from "./pages/dashboard/Covers";
import Settings from "./pages/dashboard/Settings";
import Profile from "./pages/dashboard/Profile";
import DashBoardTopNav from "./components/common/DashBoardTopNav";
import MobileDashNav from "./components/common/MobileDashNav";
import { AuthContext } from "./context/authContext";
import Error from "./components/common/Error";
import Loader from "./components/common/Loader";

const Layout = () => {
  return (
    <>
      <WebNavbar />
      <Outlet />
      <WebFooter />
    </>
  );
};

const DashboardLayout = () => {
  const {isLoading, currentUser} = useContext(AuthContext);

  useEffect(() => {

  }, [isLoading, currentUser])

  if (isLoading) {
    return <Loader containerClass={"h-screen w-full"} />
  }

  return (
    <>
    { currentUser === null ? <Navigate to="/login" replace/>  : (<Card className="2xl:container max-lg:flex-col p-[0px] gap-[0px] w-full mx-auto flex flex-row bg-white justify-between">
      <DashboardSideNav />
      <MobileDashNav />
      <Card className={"max-lg:w-full h-screen w-[85%] max-lg:rounded-[50px] rounded-l-[50px] py-[20px] px-[20px]"}>
        <DashBoardTopNav />
        <Outlet />
      </Card>
    </Card>)}</>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error /> ,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/insurances",
        element: <Products />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/loader",
    element: <Loader />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/onboarding",
    element: <OnBoarding />
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "/dashboard/home",
        element: <Dashboard />
      },
      {
        path: "/dashboard/covers",
        element: <Covers />
      },
      {
        path: "/dashboard/products",
        element: <Products />
      },
      {
        path: "/dashboard/profile",
        element: <Profile />
      },
      {
        path: "/dashboard/settings",
        element: <Settings />
      }
    ]
  }
]);

const App = () => {
  return (
    <div className="overflow-hidden">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
