import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const Button = ({
  type,
  onClick,
  className,
  buttonText,
  iconLeft,
  iconRight,
  to,
  buttonIcon = false,
  buttonStyle = "normal",
  buttonForm,
  clicked,
  disabled=false
}) => {
  const normalButton = twMerge(
    `inline-flex items-center justify-center text-white hover:text-bimalink-blue rounded-full bg-bimalink-blue border-solid border-2 border-bimalink-blue hover:bg-white font-semibold min-w-[150px] px-[20px] py-[10px] ${
      className ?? ""
    }`
  );

  const inverseButton = twMerge(
    `inline-flex items-center justify-center text-bimalink-blue hover:text-white rounded-full bg-white border-solid border-2 border-bimalink-blue hover:bg-bimalink-blue font-semibold min-w-[150px] px-[20px] py-[10px] ${
      className ?? ""
    }`
  );

  const pastelButton = twMerge(
    `inline-flex items-center justify-center text-[12px] bg-bimalink-blue-50  min-w-[88px] text-bimalink-blue-gray-800 rounded-full border-solid border-2 border-bimalink-blue-50 hover:bg-bimalink-blue-100 font-semibold px-[20px] py-[10px] ${
      className ?? ""
    } ${clicked ? "bg-bimalink-blue-200" : ""}`
  )

  const textButton = twMerge(
    `inline-flex items-center justify-center text-bimalink-blue border-none hover:text-bimalink-blue-700 font-semibold ${className ?? ""}`
  )

  const checkButton = twMerge(
    `border-none outline-none ${className}`
  )

  const buttonDisabled = twMerge(
    `inline-flex items-center justify-center text-bimalink-blue-gray-600 hover:text-bimalink-blue rounded-full bg-bimalink-blue-gray-300 border-solid border-2 border-bimalink-blue-gray-300 hover:bg-white font-semibold min-w-[150px] px-[20px] py-[10px] pointer-events-none ${
      className ?? ""
    }`
  );

  const classNames = {
    normal: normalButton,
    inverse: inverseButton,
    text: textButton,
    pastel: pastelButton,
    check: checkButton,
    disabled: buttonDisabled
  }

  return (
    <>
      { type === "input" ? <input type={buttonForm ?? "button"} className={disabled ? classNames["disabled"] : classNames["check"]}/>: (buttonIcon ? (
        type === "link" ? (
          <Link to={to} onClick={onClick} className={disabled ? "pointer-events-none" : className ?? ""}>
            {iconLeft}
            {buttonText ? buttonText : ""}
            {iconRight}
          </Link>
        ) : (
          <button
            type={`${type ? type : "button"}`}
            onClick={onClick}
            className={disabled ? "pointer-events-none" : className ?? ""}
          >
            {iconLeft}
            {buttonText ? buttonText : ""}
            {iconRight}
          </button>
        )
      ) : type === "link" ? (
        <Link to={to} onClick={onClick} className={disabled ? classNames["disabled"] : classNames[buttonStyle]}>
          {iconLeft}
          {buttonText ? buttonText : ""}
          {iconRight}
        </Link>
      ) : (
        <button
          type={`${type ? type : "button"}`}
          onClick={onClick}
          className={disabled ? classNames["disabled"] : classNames[buttonStyle]}
        >
          {iconLeft}
          {buttonText ? buttonText : ""}
          {iconRight}
        </button>
      ))}
    </>
  );
};

export default Button;
