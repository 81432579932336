import React from "react";
import { twMerge } from "tailwind-merge";
import AuthTitle from "./AuthTitle";
import Card from "./Card";

const AuthContainer = ({ title, children, className }) => {

    const classContainer = twMerge(`w-[95%] sm:px-[15px] md:w-[667px] lg:w-[667px] py-[57px] mx-auto md:px-[70px] lg:px-[70px] gap-[35px] items-center ${className}`)
  return (
    <div className="py-[100px] mx-auto">
      <Card className="bg-white p-[0px] w-full items-center justify-center">
        <AuthTitle>{title}</AuthTitle>
        <Card
          className={
            classContainer
          }
          type="form"
        >
          {children}
        </Card>
      </Card>
    </div>
  );
};

export default AuthContainer;
