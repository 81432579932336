import React from "react";

import Text from "./Text";

const Header = ({children}) => {
  return (
      <Text className="text-[14px] text-left text-bimalink-blue-gray-400 font-semibold">{children}</Text>
  );
};

export default Header;
