import React from 'react'
import { twMerge } from 'tailwind-merge'
import Card from './Card';

const Row = ({ type="row", children }) => {
    const theaderClass = twMerge(`w-full flex flex-row justify-between items-center px-[20px] bg-transparent px-[20px] py-[12px]`);
    const trowClass = twMerge(`flex-row bg-bimalink-blue-50 rounded-[15px] px-[20px] py-[12px] w-full flex justify-between items-center`)

    const classes = {
        header: theaderClass,
        row: trowClass,
    }

  return (
    <Card className={classes[type]}>
        {children}
    </Card>
  )
}

export default Row