import React from 'react'
import { twMerge } from 'tailwind-merge'

const Chip = ({chipName, className}) => {
    const classes = twMerge(`inline-flex items-center justify-center text-bimalink-blue-gray-600 rounded-full bg-bimalink-blue-gray-300 border-solid border-2 border-bimalink-blue-gray-300 font-semibold w-max px-[10px] py-[5px] text-[13px] ${className}`)
  return (
    <span className={classes}>{chipName}</span>
  )
}

export default Chip